import {createContext, useEffect, useMemo, useState} from "react";
import GlobalEvents from "../../../../events/GlobalEvents";
import BoxBrowser from "./BoxBrowser";
import {Button, Modal} from "react-bootstrap";
import SectionSelector from "./SectionSelector";
import {GET} from "../../../../services/REST";
import "./index.css";

export const BoxSearchContext = createContext();

export default () => {
    const [show, setShow] = useState(false);

    const [boxToken, setBoxToken] = useState(null);
    const [boxFolder, setBoxFolder] = useState(null);
    const [rootFolderType, setRootFolderType] = useState(null);
    const [rootFolders, setRootFolders] = useState({});

    const [selectedFile, setSelectedFile] = useState({});
    const [sectionsList, setSectionsList] = useState([]);
    const [callback, setCallback] = useState(null);
    
    const showBackBtn = useMemo(() => (
        (['epub'].indexOf(selectedFile?.extension) >= 0)
    ), [selectedFile]);

    const showConfirmBtn = useMemo(() => (
        ((['epub'].indexOf(selectedFile?.extension) < 0) || sectionsList.length > 0)
    ), [selectedFile, sectionsList]);

    useEffect(() => {
        GlobalEvents.interpret('CONTEXT_SELECTOR_SHOW', payload => {
            setCallback(() => payload.callback ?? null);

            setSectionsList([]);

            if ('default' === payload.type) {
                setBoxFolder(null);
                setRootFolderType(null);
                if (payload.folder_type) {
                    setRootFolderType(payload.folder_type)
                }
                setSelectedFile({});
                setSectionsList([]);
            }

            if ('file' === payload.type) {
                setSelectedFile(payload);
            }

            if ('folder' === payload.type) {
                setBoxFolder(payload.id);
                setSelectedFile({});
            }

            setShow(true);
        });
    }, []);

    useEffect(() => {
        GET({
            url: 'chat-from-files/box-setup',
        })
            .then(response => {
                if (response.status) {
                    setBoxToken(response.content.token);
                    setRootFolders(response.content.folders)
                }
            })
            .catch(error => {
                GlobalEvents.dispatch('SHOW_TOAST_NOTICE', {content: error.message ?? 'Unable to fetch Box config!'});
            })
            .finally(() => null);
    }, []);

    const confirm = () => {
        const data = {...selectedFile};
        if (data.subsection && 'object' === typeof data.subsection) {
            data.subsection = Object.keys(data.subsection).filter(k => !!data.subsection[k]);
        }

        callback && callback(data);
        setCallback(null);
        setShow(false);
    };

console.log("boxFolder:", boxFolder);
console.log("rootFolderType:", rootFolderType);
console.log("rootFolders:", rootFolders);
console.log("rootFolders['cognella']:", rootFolders ? rootFolders['cognella'] : undefined);
console.log("Selected folder:", 
    boxFolder 
        ? boxFolder 
        : (rootFolders && rootFolderType && rootFolders.hasOwnProperty(rootFolderType) 
            ? rootFolders[rootFolderType] 
            : (rootFolders && rootFolders.hasOwnProperty('cognella') ? rootFolders['cognella'] : null))
);

    return (
        <BoxSearchContext.Provider
            value={{
                selectedFile,
                setSelectedFile,
                sectionsList,
                setSectionsList,
                box: {
                    token: boxToken,
                    //folder: boxFolder ? boxFolder : (rootFolderType ? rootFolders[rootFolderType] : rootFolders['cognella'])
                    //folder: boxFolder ? boxFolder : (rootFolderType && rootFolders[rootFolderType] ? rootFolders[rootFolderType] : rootFolders['cognella'] || null)
                    folder: boxFolder ? boxFolder : (rootFolders && rootFolderType && rootFolders.hasOwnProperty(rootFolderType) ? rootFolders[rootFolderType] : (rootFolders && rootFolders.hasOwnProperty('cognella') ? rootFolders['cognella'] : null))
		},
                confirm: () => showConfirmBtn && confirm()
            }}
        >
            <Modal
                id="completion-v2-boxfiles-modal"
                show={show}
                fullscreen={'md-down'}
                size={'xl'}
                scrollable={true}
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Context File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedFile?.id && (['epub'].indexOf(selectedFile?.extension) >= 0)
                        ? <SectionSelector/>
                        : <span>
                            Choose a file for your conversation context. Supported file types are docx, epub, pdf and txt.
                            <BoxBrowser/>
                        </span>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {selectedFile?.id &&
                        <>
                        {showBackBtn && <Button variant={'secondary'} onClick={() => setSelectedFile(null)}>Back</Button>}
                        {showConfirmBtn && <Button variant={'success'} onClick={confirm}>Use</Button>}
                        </>
                    }
                    <Button variant={'danger'} onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </BoxSearchContext.Provider>
    );
};
